import { defineStore } from 'pinia'

export const usePerfil = defineStore({
    id: 'perfil',
    state: () => ({
      perfil: ""
    }),
    actions: {
      getPerfil(Perfil) {
        this.perfil = Perfil
      }
    },
  })