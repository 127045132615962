import { defineStore } from 'pinia'

export const useDolares = defineStore({
    id: 'dolares',
    state: () => ({
      dolar: false
    }),
    actions: {
      getDolar(dolar) {
        this.dolar = dolar
      }
    },
  })