import { pos, get, put } from "@/core/services/servicesPos";


export async function createTerminal(terminal) { 
    let  response = await pos('/terminales/crearTerminal', terminal);
    return response;
}

export async function obtenerTodasTerminales() {
    let  response = await get('/terminales/obtenerTodasTerminales');
    return response;
}

export async function obtenerTodasTerminalesFilter(sucursales) {
    let  response = await pos('/terminales/obtenerTodasTerminalesFilterSucursales', sucursales);
    return response;
}

export async function actualizarTerminales(idTerminal, terminal) {
    let response = await put(`/terminales/actualizarTerminal/${idTerminal}`, terminal); 
    return response;
}

export async function actulizarStatusTerminal(idTerminal, statusTerminal) {
    let response = await put(`/terminales/actualizarStatusTerminal/${idTerminal}`, statusTerminal); 
    return response;
}


export async function obtenerTerminal(serial) {
    let response = await get(`/terminales/obtenerTerminalSerial/${serial}`); 
    return response;
}


export async function obternerTerminalesAsignadas(idTerminal) {
    let response = await get(`/terminales/obtenerTerminalUsuario/${idTerminal}`); 
    return response;
}

export async function desvincularTerminal(idTerminal) {
    let response = await put(`/terminales/desvincularTerminal/${idTerminal}`); 
    return response;
}


export async function getTerminalID(idTerminal) {
    let response = await get(`/terminales/obtenerTerminalID/${idTerminal}`);
    return response;
}

export async function getTerminalesSucursal(idSucursal) {
    let response = await get(`/terminales/obtenerTerminalesSucursal/${idSucursal}`);
    return response;
}

