import { get, pos } from "@/core/services/servicesPos";


export async function ObtenerMovimientosDetalle(idOrder) {
    const response = await get(`/movimientos/detalleMovimientos/${idOrder}`);
    return response;
}

export async function corteCaja(corteCaja) {
    const response = await pos('/cortesCaja/cierreCorteCaja', corteCaja);
    return response;
}