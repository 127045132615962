import axios from 'axios'; 
export const RUTA_SERVIDOR = process.env.VUE_APP_API_POS;

export const USERNAME = process.env.VUE_APP_API_POS_USERNAME;
export const PASSWORD = process.env.VUE_APP_API_POS_PASSWORD;
const urlPost = RUTA_SERVIDOR;

export async function credentialsTokenAPI() {
  let credentials = JSON.stringify({
    "username": USERNAME,
    "password": PASSWORD
  });
  const response = await axios.post(`${urlPost}/user/login`, credentials,
    {
        headers: {
          'Content-Type': 'application/json'
        }
    }
  );  
  
  return response.data;     
}

export async function getTokenAPIPos() {
  let tokenAPI = (localStorage.getItem('tokenAPI'));
  if(tokenAPI) {
    return tokenAPI
  } else {
    let tokenAPI = await credentialsTokenAPI() 
    localStorage.setItem('tokenAPI', tokenAPI);

    return tokenAPI
  }
}

export async function pos(service, data) {
    let token = await getTokenAPIPos();

    const headers =  {
        headers: { 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
    const url = `${urlPost}${service}`;    
    const response = await axios.post(url, data, headers);
    return response.data;
}

export async function get(service) {
  let token = await getTokenAPIPos();

  const headers =  {
      headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }
  ;
  const url = `${urlPost}${service}`;   
  const response = await axios.get(url, headers);
  return response.data;
}

export async function put(service, data) {
  let token = await getTokenAPIPos();

  const headers =  {
      headers: { 
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }

  const url = `${urlPost}${service}`;    
  const response = await axios.put(url, data, headers);
  return response.data
}
