
export function setToken(token) {
   return localStorage.token = token;
}

export function getToken () {
    return localStorage.token;
}

export function isAuthenticated() {
    return localStorage.token != null;
}

export function removeToken() {
   return localStorage.removeItem('token');
}