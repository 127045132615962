import { pos, get, put } from "@/core/services/servicesPos";


export async function createSucursal(sucursal) { 
    let  response = await pos('/sucursales/altaSucursales', sucursal);
    return response;
}

export async function obtenerTodasSucursal() {
    let  response = await get('/sucursales/obtenerSucursales');
    return response;
}

export async function actualizarSucursal(idSucursal, sucursal) {
    let response = await put(`/sucursales/actualizarSucursal/${idSucursal}`, sucursal); 
    return response;
}

export async function actulizarStatusSucursal(idSucursal, statusSucursal) {
    let response = await put(`/sucursales/actualizarstatusSucusal/${idSucursal}`, statusSucursal); 
    return response;
}

export async function obtenerInfoTerminalSucursal(terminal) {
    let response = await get(`/sucursales/credencialesSucursal/${terminal}`);
    return response;
}

export async function obtenerInfoTerminalSucursalEmpresa(sucursal) {
    let response = await pos('/sucursales/obtenerSucursalesEmpresasUsuarios', sucursal);
    return response;
}