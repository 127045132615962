import { createApp  } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css'


import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import './assets/css/table.css'
import { createPinia } from 'pinia'


const pinia = createPinia()

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueCookies)
app.use(pinia)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('EasyDataTable', Vue3EasyDataTable);
app.component('Datepicker', Datepicker);

const options = {};

app.use(Toast, options)

app.mount('#app')

