<template>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader :empresa="empresa" :sucursal="nombreSucursal" :username="username"/>
      <div class="body flex-grow-1 px-3">
        <CContainer lg>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import { obtenerInfoTerminalSucursalEmpresa } from '@/modules/sucursales/services/servicesPos'
import { useToast } from "vue-toastification";
import { useDolares } from "@/stores/getDolares"
import { usePerfil } from "@/stores/getPerfil"
import { getUsuario } from "@/modules/users/services/servicesPos"


const toast = useToast();
export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
  data() {
    return {
      nombreSucursal: "", 
      empresa: "",
      tipoPerfil : 0,
      username: ""
    }
  },
  created() {
    this.getPerfil();
    if(Number(localStorage.getItem('tipoUsuario')) !== 4) {
        this.getTerminalesSucursales()
    }
  },
  methods: {
    async getTerminalesSucursales() {
      const dolar = useDolares()
      try {
        let sucursales = {
          "sucursales": JSON.parse(localStorage.getItem('sucursales')),
          "empresa": JSON.parse(localStorage.getItem('empresaUsuario'))
        }
        const response = await obtenerInfoTerminalSucursalEmpresa(sucursales);
        if (response.success) {
          let dolares = false
          response.data.forEach((infoSucursal) => {
             if(infoSucursal.dolares) {
                 dolares = true
             }
          });
          dolar.getDolar(dolares);
          this.empresa = response.data[0].razon_social  
          localStorage.setItem('razonSocial', response.data[0].razon_social);
          if( response.data[0].logo_empresa !== null) {
              localStorage.setItem('logoEmpresa', response.data[0].logo_empresa);
          }
         
          this.nombreSucursal = response.data.length > 1 ? "" : `- ${response.data[0].descripcion}` 
        }
      } catch (e) {
        toast.error(e.message);
      }
    },
    async getPerfil() {
      const perfil = usePerfil();
      try {
        const response = await getUsuario(localStorage.getItem('idUsuario'));
        if (response.success) {
            this.username = response.data[0].username_elsa
            perfil.getPerfil(response.data[0].nombre_rol);
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  },
}
</script>
