import { createRouter, createWebHashHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
import { isAuthenticated } from "@/core/services/auth/authServices"
import { getUsuario } from "@/modules/users/services/servicesPos"

const withToken = async (to, from, next) => {
  const response = await getUsuario(localStorage.getItem('idUsuario'));
  const perfil = response.data[0].nombre_rol;
  if(isAuthenticated() && to.meta.permission.includes(perfil)) {
    return next();
  }

   return next({
            "name": "Login"
    });
}

const withoutToken = async (to, from, next) => {
  if(isAuthenticated()) {
    const response = await getUsuario(localStorage.getItem('idUsuario'));
    const perfil = response.data[0].nombre_rol;
    if(perfil === 'SuperAdmin') {
        return next({
           "name": "AltaUsuarios"
        })
    } else {
      return next({
        "name": "NewPos"
      });
    }
  }

    return next();
}



const routes = [
  {
    path: '/pos',
    name: 'Pos',
    component: DefaultLayout,
    children: [
      {
         path: '/posi',
         name: 'NewPos',
         beforeEnter: withToken,
         component: () => import('@/modules/pos/Pos.vue'),
         meta: {
          permission: ['Admin', 'Supervisor', 'Vendedor', 'SuperAdmin'],
        },
      },
      {
        path: '/processPos/:id',
        name: 'ProccessPos',
        beforeEnter: withToken,   
        component: () => import('@/modules/pos/ProccessPos.vue'),
        meta: {
          permission: ['Admin', 'Supervisor', 'Vendedor'],
        },
      },
      {
        path: '/detallePedido/:id',
        name: 'DetallePedido',
        beforeEnter: withToken,
        component: () => import('@/modules/pos/DetallePedido.vue'),
        meta: {
          permission: ['Admin', 'Supervisor', 'Vendedor'],
        },
      },
      {
          path: '/cotizador',
          name: 'Cotizador',
          beforeEnter: withToken,
          component: () => import('@/modules/pos/Cotizador.vue'),
          meta: {
          permission: ['Admin', 'Supervisor', 'Vendedor'],
        },
      }
    ],
  },
  {
    path: '/',
    name: 'Login',
    beforeEnter: withoutToken,
    component: () => import('@/modules/login/views/Login.vue') 
  },
  {
    path: '/config',
    name: 'Config',
    beforeEnter: withToken,
    meta: {
      permission: ['Admin', 'Vendedor', 'Supervisor'],
    },
    component: () => import('@/modules/confInit/views/Config.vue') 
  },
  {
     path: '/terminales', 
     name: 'Terminales', 
     component: DefaultLayout,
     children: [
       {
         path: '/altaTerminal', 
         name: 'AltaTerminal',
         beforeEnter: withToken,
         component: () => import('@/modules/terminales/views/AltaTerminales.vue'),
         meta: {
          permission: ['Admin', 'Supervisor'],
         }
       }
     ]
  },
  {
    path: '/usuarios', 
    name: 'Usuarios',
    component: DefaultLayout,
    children: [
      {
        path: '/altaUsuario', 
        name: 'AltaUsuarios',
        beforeEnter: withToken,
        component: () => import('@/modules/users/views/AltaUsuarios.vue'),
        meta: {
          permission: ['Admin', 'SuperAdmin'],
        }
      }
    ] 
  },
  {
    path: '/sucursales',
    name: 'Sucursales', 
    component: DefaultLayout,
    children: [
       {
        path: '/altaSucursales', 
        name: 'AltaSucursales',
        beforeEnter: withToken,
        component: () => import('@/modules/sucursales/views/AltaSucursales.vue'),
        meta: {
          permission: ['Admin', 'SuperAdmin'],
        }
       }
    ]
  },
  {
    path: '/tipoCambio',
    name: 'TipoCambio', 
    component: DefaultLayout,
    children: [
       {
        path: '/altaCambio', 
        name: 'AltaCambio',
        beforeEnter: withToken,
        component: () => import('@/modules/tipoCambios/views/TipoCambios.vue'),
        meta: {
          permission: ['Admin', 'Supervisor'],
        }
       }
    ]
  },
  {
    path: '/historico',
    name: 'HistoricoPedidos',
    component: DefaultLayout,
    children: [
      {
        path: '/historicoPedidos/:idCliente?',
        name: 'HistoricoPedidosDetalle',
        beforeEnter: withToken,
        component: () => import('@/modules/historico/views/HistoricoPedidos.vue'),
        meta: {
          permission: ['Admin', 'Supervisor', 'Vendedor'],
        }
      }
    ]
  },

   /* /////////////////////////////////////////////////////////// */
  /* nueva seccion de rportes en historico */
  {
    path: '/historico',
    name: 'Reporte ventas',
    component: DefaultLayout,
    children: [
      {
        path: '/reporteVentas/:idCliente?',
        name: 'Reporte de ventas',
        beforeEnter: withToken,
        component: () => import('@/modules/historico/views/reporteVentas.vue'),
        meta: {
          permission: ['Admin', 'Supervisor', 'Vendedor'],
        }
      }
    ]
  },
  /* /////////////////////////////////////////////////////////// */
  
  {
    path: '/clientes',
    name: 'Clientes',
    component: DefaultLayout,
    children: [
      {
        path: '/clientes',
        name: 'Clientes',
        beforeEnter: withToken,
        component: () => import('@/modules/clientes/views/Clientes.vue'),
        meta: {
          permission: ['Admin', 'Supervisor', 'Vendedor'],
        }
      },
      {
          path: '/unificarClientes',
          name: 'UnificarClientes',
          beforeEnter: withToken,
          component: () => import('@/modules/clientes/views/UnificarClientes.vue'),
          meta: {
          permission: ['Admin', 'Supervisor', 'Vendedor'],
        },
      }
    ]
  },
  {
     path: '/bases',
     name: 'Bases',
     component: DefaultLayout,
     children: [
       {
         path: '/bases',
         name: 'Bases',
         beforeEnter: withToken,
         component: () => import('@/modules/bases/views/Bases.vue'),
         meta: {
          permission: ['Admin', 'Supervisor'],
        }
       }
     ] 
  },
  {
    path: '/puntos',
    name: 'Puntos',
    component: DefaultLayout,
    children: [
      {
         path: '/puntos',
         name: 'Puntos',
         beforeEnter: withToken,
         component: () => import('@/modules/puntos/views/Puntos.vue'),
         meta: {
          permission: ['Admin', 'Supervisor'],
        }
      }
    ]
  },
  {
    path: '/empresas',
    name: 'Empresas',
    component: DefaultLayout,
    children: [
       {
        path: '/empresas',
        name: 'Empresas',
        beforeEnter: withToken,
        component: () => import('@/modules/empresas/views/Empresas.vue'),
        meta: {
          permission: ['SuperAdmin'],
        }
       },
       {
          path: '/asignacionEmpresas',
          name: 'AsignacionEmpresas',
          beforeEnter: withToken,
          component: () => import('@/modules/empresas/views/AsignacionEmpresas.vue'),
          meta: {
            permission: ['SuperAdmin'],
          }
       }
    ]
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: () => import('@/views/Page404.vue') 
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})


export default router
