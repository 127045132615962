import { pos, get, put } from "@/core/services/servicesPos";

export async function createBase(base) {
    const response = await pos('/cortesCaja/inicioCorteCaja', base);
    return response;
}

export async function consultaBaseCote(idTerminal) {
    const response = await get(`/cortesCaja/consultaCorte/${idTerminal}`);
    return response
}

export async function actualizarFechaCorte(idCorte) {
    const response = await put(`/cortesCaja/updateFechaPedido/${idCorte}`);
    return response
}

export async function registerCliente(cliente) {
    const  response = await pos('/clientes/crearCliente', cliente);
    return response;
}

export async function updateCliente(idElsa, cliente) {
    const  response = await put(`/clientes/updateCliente/${idElsa}`, cliente);
    return response;
}

export async function agregarMovimientos(movimiento) {
    const response = await pos('/movimientos/agregarMovimientos', movimiento)
    return response;
}


export async function ObtenerMovimientos(idOrder) {
    const response = await get(`/movimientos/obtenerMovimientos/${idOrder}`);
    return response;
}

export async function busquedaCliente(cliente) {
    const response = await pos('/clientes/buscarCliente', cliente);
    return response;
}

export async function busquedaClienteElsa(cliente) {
    const response = await pos('/clientes/buscarClienteElsa', cliente);
    return response;
}

export async function obtenerReservaTerminal(idTerminal) {
    const response = await get(`/reservas/detalleReservaTerminal/${idTerminal}`)
    return response
}

export async function crearTicketDefault(order) {
    const response = await pos('/movimientos/guardarTicketPedido', order)
    return response
}

export async function cortesCajaTerminalSucursal(IDOrder) {
    const response = await get(`/cortesCaja/cortesCajaTerminalSucursal/${IDOrder}`)
    return response
}

export async function reimpresionTickets(IDOrder, IDSucursal) {
    const response = await put(`/tickets/reimpresionTicketsPuntos/${IDOrder}/${IDSucursal}`)
    return response
}

export async function crearTicketCortes(ticketsCarjaCorte) {
    const response = await pos('/tickets/crearTicketCorteCaja', ticketsCarjaCorte)
    return response
}
