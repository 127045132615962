export const logoNegative = [
  '556 134',
  `
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
  width="1688.000000pt" height="549.000000pt" viewBox="0 0 1688.000000 549.000000"
  preserveAspectRatio="xMidYMid meet">
 <metadata>
 Created by potrace 1.16, written by Peter Selinger 2001-2019
 </metadata>
 <g transform="translate(0.000000,549.000000) scale(0.100000,-0.100000)"
 fill="#000000" stroke="none">
 </g>
 </svg>
`,
]
